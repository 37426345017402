import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#fff',
  },
});
const LogoFull = () => {
  const classes = useStyles();



  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10000.800 120.50"
    >
      <path
        className={classes.path}
        d="M51.1,0C18.4,0,0,9.1,0,29.8V41c0,20.5,18.5,29.8,48.3,29.8c11.3,0,23.2-1.6,33-4.1V29.1H41.8v12.3h24.6v15.2
	c-4.9,0.7-12,1.4-17.3,1.4c-23.7,0-33.6-6-33.6-19v-7c0-12.9,9.4-19,35.6-19c8.4,0,19.2,1,27.6,2.7V2.7C69.6,0.9,60.4,0,51.1,0z
	 M267.2,0.6v69.5h48.4c23.4,0,33.4-10,33.4-30.9v-7.6c0-21-10-30.9-33.4-30.9H267.2z M313.9,57.2h-31.2V13.6h31.2
	c13.6,0,19.6,4.9,19.6,17.7v8.1C333.5,52.3,327.5,57.2,313.9,57.2z M136.8,0c-31.3,0-44.6,8.5-44.6,28.6v13.7
	c0,20.1,13.3,28.6,44.6,28.6s44.6-8.5,44.6-28.6V28.6C181.4,8.5,168.1,0,136.8,0z M136.8,57.9c-24.2,0-29.1-3.9-29.1-17.5v-10
	c0-13.6,4.9-17.5,29.1-17.5s29.1,3.9,29.1,17.5v10.1C165.9,54,161,57.9,136.8,57.9z M192.5,0.6v69.5h64.2V57.2H208V0.6H192.5z
	 M627.6,70.1h15.5V41.8h11.2l32.5,28.3h21.8l-40.8-35.5v-0.2l36.4-33.8h-19.7l-29.9,28.2h-11.5V0.6h-15.5V70.1z M590,0
	c-32.8,0-51.1,9.1-51.1,29.8V41c0,20.7,18.4,29.8,51.1,29.8c8.4,0,17-0.7,26.1-2.6V55.3c-8.4,1.7-18.7,2.6-26.1,2.6
	c-26.3,0-35.6-6-35.6-19v-7c0-12.9,9.4-19,35.6-19c7.4,0,17.7,0.9,26.1,2.6V2.6C607,0.7,598.4,0,590,0z M455.9,0.6v69.5h73.9V57.2
	h-58.4V40.9h54.8V28.6h-54.8v-15h57.3V0.6H455.9z M362.7,0.6v69.6h56.7c15.4,0,23.4-7.1,23.4-19c0-8.9-4.3-14.5-12.8-15.9v-1.8
	c7.6-2.1,10.6-8.4,10.6-14.3c0-12-8.1-18.6-24.4-18.6H362.7z M417.2,57.2h-38.9V40.9h38.9c6.2,0,9.5,3,9.5,8.3
	C426.7,54.4,423.3,57.2,417.2,57.2z M415.5,29.1h-37.2V13.6h37.2c6.4,0,9.4,2.5,9.4,7.8S421.9,29.1,415.5,29.1z"
      />
    </svg>
  );
};

export default LogoFull;

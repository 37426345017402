import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
  } from '@backstage/theme';
  
  export const gbTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#3EBAEC', // Highlight color
        },
        secondary: {
          main: '#27292B', // Text color
        },
        error: {
          main: '#8c4351', // Falls Sie eine spezifische Farbe für Fehler haben, bitte angeben
        },
        warning: {
          main: '#8f5e15', // Falls Sie eine spezifische Farbe für Warnungen haben, bitte angeben
        },
        info: {
          main: '#3EBAEC', // Highlight color (gleich wie primary main)
        },
        success: {
          main: '#485e30', // Falls Sie eine spezifische Farbe für Erfolg haben, bitte angeben
        },
        background: {
          default: '#FEFEFE', // Background color
          paper: '#EEEFF0', // Inner block color
        },
        banner: {
          info: '#3EBAEC', // Highlight color
          error: '#8c4351', // Falls Sie eine spezifische Farbe für Banner-Fehler haben, bitte angeben
          text: '#27292B', // Text color
          link: '#3EBAEC', // Highlight color (gleich wie primary main)
        },
        errorBackground: '#8c4351', // Falls Sie eine spezifische Farbe für Fehlerhintergrund haben, bitte angeben
        warningBackground: '#8f5e15', // Falls Sie eine spezifische Farbe für Warnungshintergrund haben, bitte angeben
        infoBackground: '#3EBAEC', // Highlight color (gleich wie primary main)
        navigation: {
          background: '#27292B', // Navigation Background
          indicator: '#3EBAEC', // Highlight color
          color: '#EEEFF0', // Drawer Item Text Color
          selectedColor: '#FFFFFF', // Selected Item Text Color
        },
      },
    }),
    defaultPageTheme: 'home',
    fontFamily: 'Arial, sans-serif', // Angepasste Schriftart
    pageTheme: {
      home: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }), // Passen Sie die Farben an, falls gewünscht
      documentation: genPageTheme({
        colors: ['#8c4351', '#3EBAEC'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      service: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      website: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      library: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      other: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      app: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
      apis: genPageTheme({ colors: ['#27292B', '#3EBAEC'], shape: shapes.round }),
    },
  });